@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");


/* Digital Marketing  */
.ad-making-page {
  background: linear-gradient(to right bottom, #131313, #3d3c3f);
  width: 100%;
}

.ad-title-div {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  padding: 5rem 0;
  --color-range: 35%;
  --background-color: rgba(128, 0, 128, var(--color-range));
  background: linear-gradient(100deg, #A364F5, #968DFA);
}


.ad-making-title {
  font: normal bold 55px/1 "Montserrat";
  color: white;
  text-shadow: 5px 4px 1px #443BAB;
}

.ad-making-title:hover {
  transform: scale(1.2);
  transition: 1s all ease-in-out;
}

.ad-making-grid1 {
  padding: 0 20px 20px 20px;
  width: 90%;
  margin: auto;
}

.ad-making-grid1-span {
  margin: auto;
}

.ad-making-grid1-card {
  margin: auto;
}

.ad-making-grid1-card:hover {
  transform: scale(1.02);
}

.ad-making-grid1-span:nth-child(even) .ad-making-grid1-card {
  background: linear-gradient(100deg, #A364F5, #968DFA);
  color: white !important;
  text-overflow: ellipsis !important;
}


.ad-title {
  background: linear-gradient(120deg, #0F089F, #E6176A);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}


.ad-marketing-image {
  width: 100%;
  height: 26vh;
}

/* Digital Marketing  End */



/* Ad mAking  */

.ad-making-grid2-span:nth-child(odd) .ad-making-grid1-card {
  background: linear-gradient(100deg, #A364F5, #968DFA);
  color: white !important;
  text-overflow: ellipsis !important;
}

/* Ad mAking  End */



/* Media Query */

@media (max-width:1650px) {
  .ad-making-grid1 {
    width: 98%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ad-marketing-image {
    width: 100%;
    height: 29vh;
  }

  .ad-title-div {
    padding: 4rem;
  }
}

@media (max-width:1060px) {
  .ad-making-grid1 {
    width: 100%;
  }
}

@media (max-width:768px) {
  .ad-marketing-image {
    width: 100%;
    height: 100%;
  }

  .ad-making-grid1 {
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px 10px 10px;
  }

  .ad-making-title {
    font: normal bold 35px/1 "Montserrat";
    color: white;
    text-shadow: 5px 4px 1px #443BAB;
    text-align: center;
  }

  .ad-title-div {
    padding: 3rem 0;
  }

}

/* Media Query End */