@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

.footer-main {
  background-color: #b7b7b7;
  /* background-color: white; */
  display: flex;
  justify-content: space-around;
  padding: 4rem 0;
  align-items: start;
  position: relative;
}

/* .footer-logo {
  width: 105px;
  height: 85px;
} */

.footer-left-div img {
  width: 125px;
  height: 125px;
}

.footer-left-div {
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 5px;
}

.footer-left-div h3 {
  font: normal bold 40px/1 "Montserrat";
  background: linear-gradient(150deg, #0F089F, #E6166A);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  margin: 0;
}

.footer-content {
  font: normal 20px/1 "Montserrat";
  color: black;
}

.footer-right ul {
  list-style: none;
}

.links {
  text-decoration: none;
  list-style-type: none;
}

.footer-right ul li:hover {
  background: linear-gradient(150deg, #0F089F,#E6166A);
  /* transition: all 0.5s ease-in-out; */
  color: white;
}

.footer-right h3 {
  font: normal bold 24px/1 "Montserrat";
  margin-bottom: 2rem;
  color: black;
}

.footer-right ul li {
  color: black;
  font: normal bold 20px/1 "Montserrat";
  font-weight: 200;
  margin-bottom: 15px;
  width: fit-content;
  padding: 10px 20px;
  border-radius: 10px;
}

.footer-right-two h3 {
  font: normal bold 24px/1 "Montserrat";
  margin-bottom: 2rem;
  color: black;
}

.newsletter-input {
  padding: 12px 20px;
  outline: none;
  border: none;
  width: 220px;
}

.newsletter-btn {
  outline: none;
  border: none;
  padding: 12px 40px;
  background: linear-gradient(#5d50f8, #6f0aef);
  color: #ffffff;
  font: normal bold 17px/1 "Montserrat";
  cursor: pointer;
  cursor: pointer;
  transition: 0.5s all ease;
}

.newsletter-btn:hover {
  transform: scale(1.1);
}

.footer-map-iframe {
  height: 30vh;
  position: absolute;
  width: 70%;
}

/* Media Querry */

@media (max-width: 1060px) {
  .footer-main {
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 2rem;
    padding: 2rem 10px;
  }

  .footer-right-two {
    padding: 10px;
    width: 100%;
  }

  .footer-left {
    width: 100%;
  }

  .footer-map-iframe {
    height: 30vh;
    width: 100%;
    margin: auto;
    position: relative;
  }
}

.gap_10 {
  gap: 10px !important;
  display: flex;
  justify-content: center;
}

@media (max-width:550px) {
  .gap_10 {
    flex-direction: column;
    gap: 0 !important;
    justify-content: center;
  }

  .newsletter-btn {
    transform: scale(.8);
    left: -5rem;
    z-index: -111;
  }

  .footer-left-div h3 {
    font: normal bold 23px/1 "Montserrat";
    color: #f2f2f2;
    margin: 0;
  }

  .footer-main {
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 0rem !important;
    padding: 2rem 10px;
  }
}


.gap_10 {
  gap: 10px !important;
  display: flex;
  justify-content: center;
}